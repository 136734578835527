<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="UsersIcon"/>
      <span>Departments</span>
    </template>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-row>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="First Name" rules="required">
                  <b-form-group label="First Name">
                    <b-form-input trim placeholder="First Name" v-model="dataInfo.first_name" :state="getValidationState(validationContext)" id="form-first-name"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="Last Name" rules="required">
                  <b-form-group label="Last Name">
                    <b-form-input trim placeholder="Last Name" v-model="dataInfo.last_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="Email" rules="email|required">
                  <b-form-group label="Email">
                    <b-form-input trim placeholder="Email" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="Phone">
                  <b-form-group label="Phone">
                    <b-form-input trim placeholder="Phone" v-model="dataInfo.phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="Is Academy" rules="required">
                  <b-form-group label="Is Academy" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.is_academy" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="Sport">
                  <b-form-group label="Sport" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.sports_id" :options="sportOptions" :reduce="val => val.value" :clearable="false" multiple="multiple"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider #default="validationContext" name="Status" rules="required">
                  <b-form-group label="Status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <div class="d-flex mt-2 justify-content-center">
                  <b-button variant="primary" class="mr-2" type="submit">Save</b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <div class="d-flex mt-2 justify-content-center">
          <b-button variant="outline-primary" class="mr-2" type="submit" @click="importModalShow=true">Import CSV File</b-button>
        </div>

      </div>
    </b-card>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row class="mb-1">
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
              <DepartmentDetail :is-contact-new-sidebar-active.sync="isContactNewSidebarActive" :record-data-id="contactRecordDataId"></DepartmentDetail>
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                  <b-button @click="isContactNewSidebarActive = true; contactRecordDataId=0" variant="primary" size="md">
                    <feather-icon icon="PlusCircleIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>

        <b-table
            ref="refListTable"
            primary-key="id"
            empty-text="No record found"
            :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
            :items="fetchList"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            responsive striped bordered hover show-empty>

          <template #cell(id)="data">
            <b-link @click="changeContact(data.item)" class="font-weight-bold">#{{ data.value }}</b-link>
          </template>

          <template #cell(status)="data">
            <active-column-table :data="data.value"></active-column-table>
          </template>

          <template #cell(is_academy)="data">
            <boolean-column-table :data="data.value"></boolean-column-table>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon :id="`row-${data.item.id}-edit`" icon="EditIcon" size="16" class="mr-1" @click="changeContact(data.item)"/>
              <feather-icon :id="`row-${data.item.id}-remove`" icon="TrashIcon" size="16" @click="removeContact(data.item)"/>
            </div>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <footer-table :data-meta="dataMeta"></footer-table>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>

              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <Overlay :busy="busy"></Overlay>
      </div>
    </b-card>

    <b-modal v-model="importModalShow" ref="message-modal" title="Import CSV File" cancel-title="Close" cancel-variant="outline-secondary" size="lg" @ok="submitCsvFile" centered no-close-on-backdrop>
      <b-form-group label="CSV File">
        <b-form-file plain id="import_file" name="import_file" accept=".csv" @input="csvFileRenderer()"/>
      </b-form-group>

      <b-form-group label="Import Content">
        <b-form-textarea trim placeholder="Import Content" rows="15" v-model="csv_file_content"/>
      </b-form-group>

    </b-modal>

  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BForm, BFormGroup, BFormInvalidFeedback, BFormFile, BFormTextarea} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue"
import DepartmentDetail from "@/views/university/department/Detail.vue"
import Vue from "vue"
import store from "@/store"
import router from "@/router"
import {ref, watch} from '@vue/composition-api'
import useList from './useList'
import {ActiveColumnTable, DateColumnTable, FooterTable, BooleanColumnTable} from "@/components/Table";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import {required} from '@validations'
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import {statusOptions, yesNoOptions} from "@core/utils/filter"

export default {
  name: "DepartmentList",
  components: {
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback, BFormGroup, BForm,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,

    Overlay,
    FooterTable,
    DateColumnTable,
    ActiveColumnTable,
    BooleanColumnTable,

    vSelect,
    DepartmentDetail,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  setup() {

    const toast = useToast()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,

      fetchList,
      reFetchData,
    } = useList()

    const isContactNewSidebarActive = ref(false)
    const contactRecordDataId = ref(0)
    const importModalShow = ref(false)
    const csv_file_content = ref('')

    const changeContact = (dataItem) => {
      contactRecordDataId.value = dataItem.id
      isContactNewSidebarActive.value = true
    }

    const removeContact = (dataItem) => {
      Vue.swal({
        title: 'Are you sure?',
        text: 'Record deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeDepartment', {universityId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
            reFetchData()
            Vue.swal({
              icon: 'success',
              title: 'Success!',
              text: 'Record deleted',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    watch(isContactNewSidebarActive, (dataValue) => {
      if (dataValue === false) {
        reFetchData()
      }
    })

    const blankRecordData = {
      id: 0,
      university_id: router.currentRoute.params.id,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      is_academy: false,
      sports_id: null,
      status: 1,
    }

    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const sportOptions = ref([])
    store.dispatch('store/sportItems').then(response => {
      response.data.data.forEach((value, index) => {
        sportOptions.value.push({label: value.name, value: value.id})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editDepartment' : 'addDepartment'), {universityId: router.currentRoute.params.id, dataInfo: dataInfo.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        resetRecordData()
        reFetchData()
        document.getElementById("form-first-name").focus();
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const csvFileRenderer = () => {
      const fileInput = document.getElementById('import_file')
      const reader = new FileReader()
      reader.onload = () => {
        csv_file_content.value = reader.result
      }
      reader.readAsBinaryString(fileInput.files[0])
    }

    const submitCsvFile = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      busy.value = true
      store.dispatch('store/importCsv', {universityId: router.currentRoute.params.id, data_content: csv_file_content.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        reFetchData()
        importModalShow.value = false
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    watch([importModalShow], () => {
      if (importModalShow.value === true) {
        csv_file_content.value = '';
      }
    })

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isVisible,
      dataInfo,
      importModalShow,
      isContactNewSidebarActive,
      contactRecordDataId,
      refFormObserver,
      sportOptions,
      csv_file_content,

      fetchList,
      reFetchData,
      getValidationState,
      resetForm,
      onSubmit,

      changeContact,
      removeContact,
      submitCsvFile,
      csvFileRenderer,

      statusOptions,
      yesNoOptions,
    }
  }
}
</script>
