<template>
  <b-card>
    <b-tabs pills content-class="border-top">
      <b-tab active>
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>General</span>
        </template>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
            <div class="d-flex mt-2">
              <feather-icon icon="BookIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">General Information</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Division" rules="required">
                  <b-form-group label="Division" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.division" :options="divisionOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Name" rules="required">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Status" rules="required">
                  <b-form-group label="Status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="MailIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">E-Mail Information</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Undergraduate Admission E-Mail" rules="email">
                  <b-form-group label="Undergraduate Admission E-Mail">
                    <b-form-input trim placeholder="Undergraduate Admission E-Mail" v-model="dataInfo.undergraduate_admission_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="International Admission E-Mail" rules="email">
                  <b-form-group label="International Admission E-Mail">
                    <b-form-input trim placeholder="International Admission E-Mail" v-model="dataInfo.international_admission_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Athlete Admission E-Mail" rules="email">
                  <b-form-group label="Athlete Admission E-Mail">
                    <b-form-input trim placeholder="Athlete Admission E-Mail" v-model="dataInfo.athlete_admission_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="MapPinIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">Address Information</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Phone Number">
                  <b-form-group label="Phone Number">
                    <b-form-input trim placeholder="Phone Number" v-model="dataInfo.phone_number" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="City">
                  <b-form-group label="City">
                    <b-form-input trim placeholder="City" v-model="dataInfo.city" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="State">
                  <b-form-group label="State">
                    <v-select v-model="dataInfo.state_id" :options="states" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Website">
                  <b-form-group label="Website">
                    <b-form-input trim placeholder="Website" v-model="dataInfo.website" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Location">
                  <b-form-group label="Location">
                    <v-select v-model="dataInfo.location" :options="locations" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">Minimum GPA Information</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Minimum GPA">
                  <b-form-group label="Minimum GPA">
                    <b-form-input trim placeholder="Minimum GPA" v-model="dataInfo.minimum_gpa" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Minimum GPA (out of)">
                  <b-form-group label="Minimum GPA (out of)">
                    <b-form-input trim placeholder="Minimum GPA (out of)" v-model="dataInfo.minimum_gpa_out_of" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">Other Information</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Tuition and Fees">
                  <b-form-group label="Tuition and Fees">
                    <b-form-input trim placeholder="Tuition and Fees" v-model="dataInfo.tuition_and_fees" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Total Enrollment">
                  <b-form-group label="Total Enrollment">
                    <b-form-input trim placeholder="Total Enrollment" v-model="dataInfo.total_enrollment" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Fall Acceptance Rate">
                  <b-form-group label="Fall Acceptance Rate">
                    <b-form-input trim placeholder="Fall Acceptance Rate" v-model="dataInfo.fall_acceptance_rate" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Average Freshman Retention Rate">
                  <b-form-group label="Average Freshman Retention Rate">
                    <b-form-input trim placeholder="Average Freshman Retention Rate" v-model="dataInfo.average_freshman_retention_rate" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Six Year Graduation Rate">
                  <b-form-group label="Six Year Graduation Rate">
                    <b-form-input trim placeholder="Six Year Graduation Rate" v-model="dataInfo.six_year_graduation_rate" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <action-buttons :back-route="'university-list'"/>
          </b-form>
        </validation-observer>
      </b-tab>
      <DepartmentList></DepartmentList>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/university/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, genderOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import DepartmentList from "@/views/university/department/List.vue";

export default {
  components: {
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,
    DepartmentList,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      division: 1,
      name: '',
      undergraduate_admission_email: '',
      international_admission_email: '',
      athlete_admission_email: '',
      phone_number: '',
      city: '',
      state_id: null,
      website: '',
      location: null,
      tuition_and_fees: 0,
      total_enrollment: 0,
      fall_acceptance_rate: 0,
      average_freshman_retention_rate: 0,
      six_year_graduation_rate: 0,
      minimum_gpa: 0,
      minimum_gpa_out_of: 0,
      status: 1,
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'university-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
        if (error.response.status === 403) {
          router.push({name: 'university-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const states = [
      {label: 'Alaska', value: 1},
      {label: 'Alabama', value: 2},
      {label: 'Arkansas', value: 3},
      {label: 'Arizona', value: 4},
      {label: 'California', value: 5},
      {label: 'Colorado', value: 6},
      {label: 'Connecticut', value: 7},
      {label: 'District of Columbia', value: 8},
      {label: 'Delaware', value: 9},
      {label: 'Florida', value: 10},
      {label: 'Georgia', value: 11},
      {label: 'Hawaii', value: 12},
      {label: 'Iowa', value: 13},
      {label: 'Idaho', value: 14},
      {label: 'Illinois', value: 15},
      {label: 'Indiana', value: 16},
      {label: 'Kansas', value: 17},
      {label: 'Kentucky', value: 18},
      {label: 'Louisiana', value: 19},
      {label: 'Massachusetts', value: 20},
      {label: 'Maryland', value: 21},
      {label: 'Maine', value: 22},
      {label: 'Michigan', value: 23},
      {label: 'Minnesota', value: 24},
      {label: 'Missouri', value: 25},
      {label: 'Mississippi', value: 26},
      {label: 'Montana', value: 27},
      {label: 'North Carolina', value: 28},
      {label: 'North Dakota', value: 29},
      {label: 'Nebraska', value: 30},
      {label: 'New Hampshire', value: 31},
      {label: 'New Jersey', value: 32},
      {label: 'New Mexico', value: 33},
      {label: 'Nevada', value: 34},
      {label: 'New York', value: 35},
      {label: 'Ohio', value: 36},
      {label: 'Oklahoma', value: 37},
      {label: 'Oregon', value: 38},
      {label: 'Pennsylvania', value: 39},
      {label: 'Rhode Island', value: 40},
      {label: 'South Carolina', value: 41},
      {label: 'South Dakota', value: 42},
      {label: 'Tennessee', value: 43},
      {label: 'Texas', value: 44},
      {label: 'Utah', value: 45},
      {label: 'Virginia', value: 46},
      {label: 'Vermont', value: 47},
      {label: 'Washington', value: 48},
      {label: 'Wisconsin', value: 49},
      {label: 'West Virginia', value: 50},
      {label: 'Wyoming', value: 51}
    ];

    const locations = [
      {label: 'Southeast', value: 1},
      {label: 'Midwest', value: 2},
      {label: 'NewEngland', value: 3},
      {label: 'Boston', value: 4},
      {label: 'MidAtlantic', value: 5},
    ];

    const divisionOptions = [
      {label: 'Division 1', value: 1},
      {label: 'Division 2', value: 2},
      {label: 'Division 3', value: 3},
      {label: 'Division 4', value: 4},
    ];

    return {
      busy,
      dataInfo,
      refFormObserver,
      states,
      locations,
      divisionOptions,

      genderOptions,
      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
